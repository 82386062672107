import styled from "styled-components"

export const ProfileStyles = styled.div`
  background-color: #eee7fb;
  position: relative;
  min-height: 150px;
  padding: 20px;
  padding-left: 120px;
  margin-left: 18px;
  margin-top: 38px;
  margin-bottom: 20px;

  flex-basis: 50%;
  flex-wrap: wrap;

  img {
    border-radius: 50%;
    border: 4px solid #fff;

    position: absolute;
    top: -18px;
    left: -18px;
    width: 120px;
    height: 120px;

    transition: all 0.5s;

    &:hover {
      width: 150px;
      height: 150px;
      top: -25px;
      left: -25px;
    }
  }

  h2 {
    font-size: 1.6rem;
    font-weight: bold;
    color: #444;
    margin-bottom: 2px;

    & small {
      font-size: 1.2rem;
      font-weight: bold;
      color: #555;
      margin-bottom: 8px;
      display: block;
      text-transform: uppercase;
    }
  }

  p {
    margin-bottom: 0;
    font-size: 1.4rem;
  }

  @media (max-width: 600px) {
    flex-basis: auto;
    flex-wrap: no-wrap;
    margin-top: 18px;

    img {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media (min-width: 601px) and (max-width: 900px) {
    padding-left: 20px;
    padding-top: 102px;
    margin-top: 56px;
    text-align: center;

    img {
      top: -36px;
      left: 50%;
      transform: translateX(-50%);

      &:hover {
        top: -43px;
        left: inherit;
        width: 150px;
        height: 150px;
      }
    }
  }
`
