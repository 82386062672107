import {useState, useEffect} from 'react';

export default (sliderImages, duration = 4000) => {
    
    const [slideState, setSlideState] = useState({
        currentIndex: 0,
        currentSlide: sliderImages[0],
    })

    useEffect(() => {

        const interval = setInterval(() => {
            setSlideState(oldState => {               
                const newIndex = (oldState.currentIndex + 1) % sliderImages.length;
                const newState = {
                    ...oldState,
                    currentIndex: newIndex,
                    currentSlide: sliderImages[newIndex]
                }
                
                return newState;
            })
        }, duration);

        return () => {
            clearInterval(interval);
        }
    }, [])

    return slideState.currentSlide;
}