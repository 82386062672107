import React from "react"
import { StaticQuery } from "gatsby"

import Layout from "../components/layout/layout"
import ImagePreloader from "../components/image-preloader/image-preloader"
import Slider from "../components/slider/slider"

import TeamMemberList from "../components/team-members/team-member-list"

const HOME_QUERY = graphql`
  {
    site {
      siteMetadata {
        homePoints {
          text
          image
        }
      }
    }
    allSanityTeamMember {
      edges {
        node {
          id
          name
          jobTitle
          bio
          image {
            asset {
              url
            }
          }
        }
      }
    }
  }
`

const IndexPage = () => (
  <StaticQuery
    query={HOME_QUERY}
    render={data => (
      <Layout>
        {/* 
            Have other components that wraps the more generic render props image preloader for specific actions 
            i.e. one that shows nothing, or one that allows you to set replacement text etc 
        */}
        <ImagePreloader
          images={data.site.siteMetadata.homePoints.map(p => p.image)}
        >
          {imagesLoaded => {
            if (imagesLoaded)
              return <Slider slides={data.site.siteMetadata.homePoints} />

            return <p>Loading ...</p>
          }}
        </ImagePreloader>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores
          dicta illum quia doloremque, expedita voluptate quae commodi nihil hic
          quaerat nemo possimus maxime esse laboriosam ratione nobis illo
          quibusdam facere. Lorem ipsum dolor sit amet, consectetur adipisicing
          elit. Dolor excepturi sed, totam labore reprehenderit corporis dicta?
          Blanditiis, quaerat! Recusandae veniam iure alias quo neque quia
          accusamus ipsum deserunt minus voluptate?
        </p>
        <TeamMemberList
          team={data.allSanityTeamMember.edges.map(edge => {
            const { id, name, jobTitle, bio, image } = edge.node
            return {
              id,
              name,
              image: image.asset.url,
              jobTitle,
              bio,
            }
          })}
        />
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. In fugit
          repudiandae voluptates! Hic sunt illo quaerat quibusdam. Vitae vel
          voluptates amet earum. Esse quia veniam mollitia eius sit distinctio
          iure, consequuntur iusto, quod rerum error asperiores corrupti quaerat
          impedit, provident incidunt. Eum harum sapiente officiis sit natus
          nobis, odit nihil ipsum atque, nisi quia veritatis porro? Cum
          consequatur labore aliquam veniam ea omnis fugit sint eveniet
          consectetur illum ad amet a doloremque, incidunt minus optio atque
          quis consequuntur dicta eius.
        </p>
      </Layout>
    )}
  />
)

export default IndexPage
