import React from "react"
import TeamMember from "./team-member"

import { TeamMembersList } from "./team-member-list-styles"

export default ({ team }) => {
  return (
    <TeamMembersList>
      {team.map(profile => (
        <TeamMember key={profile.id} profile={profile} />
      ))}
    </TeamMembersList>
  )
}
