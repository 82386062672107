import styled from "styled-components"

export const SlideWrapper = styled.div`
  min-height: 250px;
  border: 1px;
  background-color: #ccc;
  position: relative;
  overflow: hidden;
  margin: -20px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    min-height: 200px;
  }

  .slide-enter {
    transition: transform 0.5s ease;
    transform: translateX(100%);
  }

  .slide-enter-active {
    transform: translateX(0);
  }

  .slide-exit {
    transition: transform 0.5s ease;
    transform: translateX(0);
  }
  .slide-exit-active {
    transform: translateX(-100%);
  }
`

export const Slide = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url('${props => props.bgImage}');
    background-size: cover;
    background-position: center center;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    p {
        color: #fff;
        font-size: 4.5rem;
        font-weight: bold;
        font-family: verdana, arial, helvetica;
        padding: 5px 20px;
        margin-bottom: 30px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: rgba(0,0,0,0.5);

        @media (max-width: 600px) {
          font-size: 3rem;
          margin-bottom: 20px;
        }
    }

`
