import styled from "styled-components"

export const TeamMembersList = styled.div`
  display: flex;
  flex-direction: row;

  margin-bottom: 18px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`
