import { useState, useEffect } from 'react';

export default (imageUrlArray) => {
    const [allLoaded, setAllLoaded] = useState(false);

    useEffect(() => {
        // TODO: Hande the case where an image fails to load.
        Promise.all(
            imageUrlArray.map(imageUrl => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = ((e) => {
                        resolve();
                    });
                    img.src = imageUrl;
                });
            })
        ).then(() => {
            setAllLoaded(true);
        })

    }, []);

    return allLoaded;
};