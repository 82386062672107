import React from "react"

import { TransitionGroup, CSSTransition } from "react-transition-group"

import { SlideWrapper, Slide } from "./slider-styles"

import useSlider from "../../hooks/useSlider"

export default ({ slides }) => {
  // TODO: add the duration as a prop.

  const currentSlide = useSlider(
    slides.map((slide, index) => ({ ...slide, id: index })),
    3000
  )

  return (
    <TransitionGroup component={SlideWrapper}>
      <CSSTransition key={currentSlide.id} timeout={3000} classNames="slide">
        <Slide bgImage={currentSlide.image}>
          <p>{currentSlide.text}</p>
        </Slide>
      </CSSTransition>
    </TransitionGroup>
  )
}
