import React from "react"

import { ProfileStyles } from "./team-member-styles"

export default ({ profile }) => {
  const { name, image, jobTitle, bio } = profile

  return (
    <>
      <ProfileStyles>
        <img src={image} /> {/* Make this a gatsby image */}
        <h2>
          {name}
          <small>{jobTitle}</small>
        </h2>
        <p>{bio}</p>
      </ProfileStyles>
    </>
  )
}
